import React from 'react'
import NavBar from '../../common/components/NavBar'
import LandingPage from '../../common/components/LandingPage'

const Home = () => {
  return (
    <div>
      <NavBar />
      <LandingPage />
    </div>
  )
}

export default Home