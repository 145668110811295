import { Menu } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "./styles.css";
import { IBlocks } from ".";
import NestedAction from "./NestedAction";
import {
  FolderSpecialOutlined,
  FolderDeleteOutlined,
} from "@mui/icons-material";

interface IProps {
  index: number;
  onRichTextClick: () => void;
  block: IBlocks;
  setBlocks: Dispatch<SetStateAction<IBlocks[]>>;
  handleBlockLevelSave: (b: IBlocks, index: number) => void;
  setOpenImageUpload: Dispatch<SetStateAction<boolean>>;
  setOpenVideoUpload: Dispatch<SetStateAction<boolean>>;
  setOpenDocumentUpload: Dispatch<SetStateAction<boolean>>;
  setOpenEmbed: Dispatch<SetStateAction<boolean>>;
  setOpenPreviewBlock: Dispatch<SetStateAction<boolean>>;
  setCurrentBlock: Dispatch<SetStateAction<IBlocks | undefined>>;
  setShowConfirmModal: Dispatch<SetStateAction<boolean>>;
  setInsertIndex: (b: number | null) => void;
  blocks: IBlocks[];
  setIsEdit?: Dispatch<SetStateAction<boolean>>;
  handleClearStates: () => void;
}

const Action = (props: IProps) => {
  const [anchorEl2, setAnchorEl2] = React.useState<HTMLButtonElement | null>(
    null
  );
  const {
    index,
    onRichTextClick,
    block,
    setBlocks,
    blocks,
    setOpenDocumentUpload,
    setOpenImageUpload,
    setOpenVideoUpload,
    setInsertIndex,
    setOpenEmbed,
    setOpenPreviewBlock,
    setCurrentBlock,
    setIsEdit,
    handleBlockLevelSave,
    handleClearStates,
    setShowConfirmModal,
  } = props;

  const handleClick2 = (event: any) => {
    setInsertIndex(index);
    setAnchorEl2(event.currentTarget);
    setCurrentBlock(block);
  };

  const handleClose = () => {
    setInsertIndex(null);
    setAnchorEl2(null);
  };

  useEffect(() => {
    return () => {
      setAnchorEl2(null);
    };
  }, []);

  return (
    <div className="block-wrapper">
      <div className="block-actions">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <span className="save-status">
            {block.saved ? <FolderSpecialOutlined /> : <FolderDeleteOutlined />}
          </span>
          <MoreVertIcon
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick2}
          />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl2}
          // keepMounted
          aria-haspopup="true"
          open={Boolean(anchorEl2)}
          onClose={handleClose}
        >
          <div key={index} className="action-div">
            {!block.saved ? (
              <div
                className="popper-child-action"
                onClick={async () => {
                  await onRichTextClick();
                  handleBlockLevelSave(block, index);
                }}
              >
                Save
              </div>
            ) : (
              <></>
            )}
            <div
              className="popper-child-action"
              onClick={() => {
                setIsEdit && setIsEdit(true);
                block.type === "image" && setOpenImageUpload(true);
                block.type === "video" && setOpenVideoUpload(true);
                block.type === "document" && setOpenDocumentUpload(true);
                block.type === "embed" && setOpenEmbed(true);
              }}
            >
              Edit
            </div>
            <div
              className="popper-child-action"
              onClick={() => {
                setCurrentBlock(block);
                setOpenPreviewBlock(true);
                setAnchorEl2(null);
              }}
            >
              Preview
            </div>
            <div
              className="popper-child-action"
              onClick={() => {
                setShowConfirmModal(true);
                setAnchorEl2(null);
              }}
            >
              Delete
            </div>
            <NestedAction
              onRichTextClick={onRichTextClick}
              setOpenDocumentUpload={setOpenDocumentUpload}
              setOpenImageUpload={setOpenImageUpload}
              setOpenVideoUpload={setOpenVideoUpload}
              setAnchorEl2={setAnchorEl2}
              setOpenEmbed={setOpenEmbed}
              handleClearStates={handleClearStates}
            />
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default Action;
