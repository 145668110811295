import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import auth from "../../config/auth";
import { useCurrentUser } from "../../common/context/CurrentUser";
import Auth from "../../common/services/UserService";
import routes from "../../config/routes";
import styles from './userlayout.module.scss'

const UserLayout: React.FC = () => {
  const isAuthed = auth.isAuthenticated();
  const { currentUser, setValues } = useCurrentUser();
  const navigate = useNavigate();

  let group: string = currentUser?.groups[0];

  useEffect(() => {
    if (!isAuthed) {
      navigate("/login", { state: { toPath: window.location.pathname } });
    }
    if (isAuthed) {
      Auth.getUser("me")
        .then((response: any) => {
          setValues({
            username: response?.username,
            first_name: response?.first_name,
            middle_name: response?.middle_name,
            last_name: response?.last_name,
            groups: response?.groups,
            profile_picture: response?.profile_picture,
            email: response?.profile_picture,
            display_name: response?.display_name,
          });
        })
        .catch(() => {
          navigate(routes.login.path);
        });
    }
    // eslint-disable-next-line
  }, [isAuthed, navigate]);

  return isAuthed ? <div className={styles.layout}>
 <Outlet />
  </div> : <Navigate to="/login" replace />;
};

export default UserLayout;
