import { lazy } from "react";

import * as paths from "../common/constants/routes";

const routes = {
  // dashboard: {
  //     path: paths.DASHBOARD,
  //     component: lazy(() => import('pages/Dashboard')),
  //   },
  home: {
    path: paths.HOME,
    component: lazy(() => import("../pages/Home")),
  },
  login: {
    path: paths.LOGIN,
    component: lazy(() => import("../pages/Login")),
  },
  dashboard: {
    path: paths.DASHBOARD,
    component: lazy(() => import("../pages/Admin/Dashboard")),
  },
  ppo: {
    path: paths.PPO,
    component: lazy(() => import("../pages/PackksizeOverview/PPO")),
  },
  ppss: {
    path: paths.PPSS,
    component: lazy(() => import("../pages/PackksizeOverview/PPSS")),
  },
  ppotv: {
    path: paths.PPOTV,
    component: lazy(() => import("../pages/PackksizeOverview/PPOTV")),
  },
  ppsrg: {
    path: paths.PPSRG,
    component: lazy(() => import("../pages/PackksizeOverview/PPSRG")),
  },
  psse: {
    path: paths.PSSE,
    component: lazy(() => import("../pages/PackksizeOverview/PSSE")),
  },
  pcs: {
    path: paths.PCS,
    component: lazy(() => import("../pages/PackksizeOverview/PCS")),
  },
  pior: {
    path: paths.PIOR,
    component: lazy(() => import("../pages/PackksizeOverview/PIOR")),
  },
  pbcr: {
    path: paths.PBCR,
    component: lazy(() => import("../pages/PackksizeOverview/PBCR")),
  },
  pamc: {
    path: paths.PAMC,
    component: lazy(() => import("../pages/PackksizeOverview/PAMC")),
  },
  ppsf: {
    path: paths.PPSF,
    component: lazy(() => import("../pages/PackksizeOverview/PPSF")),
  },
  x5cm: {
    path: paths.X5CM,
    component: lazy(() => import("../pages/CADFiles/X5CADModels")),
  },
  x7cm: {
    path: paths.X7CM,
    component: lazy(() => import("../pages/CADFiles/X7CADModels")),
  },
  x4cm: {
    path: paths.X4CM,
    component: lazy(() => import("../pages/CADFiles/X4CADModels")),
  },
  em7cm: {
    path: paths.EM7CM,
    component: lazy(() => import("../pages/CADFiles/EM7CADModels")),
  },
  iqfcm: {
    path: paths.IQFCM,
    component: lazy(() => import("../pages/CADFiles/IQFusionCADModels")),
  },
  agcm: {
    path: paths.AGCM,
    component: lazy(() => import("../pages/CADFiles/AutoGluerCADModels")),
  },
};

export default routes;
