import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { IBlocks } from '../../../common/components/CustomEditor';
import CustomParser from '../../../common/components/CustomEditor/CustomParser';
import Footer from "../../../common/components/Footer";
import NavBar from "../../../common/components/NavBar";
import pamcService from "../../../common/services/Pamc";
import { toast } from "react-toastify";

const PacksizeAprovedMarketingContent = () => {
  const [data, setData] = useState<IBlocks[]>([]);
  useEffect(() => {
    pamcService
      .getPAMC()
      .then((res: any) => {
        const dataString = res[0]?.htmltext;
        setData(JSON.parse(dataString).data);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  }, [data]);

  return (
    <>
      <>
        <NavBar />
        <Container>
          {
            data && data.map((block) => {
              return <CustomParser block={block} />
            })
          }
        </Container>
        <Footer />
      </>
    </>
  );
};

export default PacksizeAprovedMarketingContent;
