import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { IBlocks } from '../../../common/components/CustomEditor';
import CustomParser from '../../../common/components/CustomEditor/CustomParser';
import Footer from "../../../common/components/Footer";
import NavBar from "../../../common/components/NavBar";
import ppsfService from "../../../common/services/Ppsf";
import { toast } from "react-toastify";

const PacksizeProjectSubmissionForm = () => {
  const [data, setData] = useState<IBlocks[]>([]);
  useEffect(() => {
    ppsfService
      .getPPSF()
      .then((res: any) => {
        const dataString = res[0]?.htmltext;
        setData(JSON.parse(dataString).data);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        {
          data && data.map((block) => {
            return <CustomParser block={block} />
          })
        }
      </Container>
      <Footer />
    </>
  );
};

export default PacksizeProjectSubmissionForm;
