import React from 'react'
import style from './style.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faInstagram,
  faYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
  return (
    <>
    <div className={style.footerBar}>
      <div className={style.footer}>
        <div className={style.footerLink}>
          <div className={style.packsizeCopyright}>
            © 2023 Packsize International, Inc.
            &nbsp;
            All Rights Reserved
          </div>
          <div className={style.footerNavLink}>
            <a href='https://www.packsize.com/contact-us/' target='_blank' rel='noopener noreferrer'>
              <span>Contact Us</span>
            </a>
            <a href='https://www.packsize.com/privacy-policy/' target='_blank' rel='noopener noreferrer'>
              <span>Privacy Policy</span>
            </a>
            <a href='https://www.packsize.com/terms-of-use/' target='_blank' rel='noopener noreferrer'>
              <span>Terms of Use</span>
            </a>
            <a href='https://www.packsize.com/careers/' target='_blank' rel='noopener noreferrer'>
              <span>Careers</span>
            </a>
          </div>
        </div>
        <div className={style.socialMediaIcons}>
          <a href='https://www.youtube.com/user/Packsize' target='_blank' rel="noopener noreferrer" className={style.socialMediaLink}>
            <FontAwesomeIcon icon={faYoutube} className='fa-brands fa-youtube' style={{ width: "30px" }} />
          </a>
          <a href="https://www.instagram.com/packsizellc/" target="_blank" rel="noopener noreferrer" className={style.socialMediaLink}>
            <FontAwesomeIcon icon={faInstagram} className="fa-brands fa-instagram" style={{ width: "30px" }} />
          </a>
          <a href='https://www.linkedin.com/company/packsize/' target='_blank' rel="noopener noreferrer" className={style.socialMediaLink}>
            <FontAwesomeIcon icon={faLinkedin} className='fa-brands fa-linkedin' style={{ width: "30px" }} />
          </a>
        </div>

      </div>
    </div>
    </>
  )
}

export default Footer