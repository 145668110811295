import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import routes from "../../config/routes";
import Login from "../../pages/Login";
import Dashboard from "../../pages/Admin/Dashboard";
import AdminLayout from "../../Layouts/AdminLayout";
import AutoGluerCADModels from "../../pages/CADFiles/AutoGluerCADModels";
import EM7CADModels from "../../pages/CADFiles/EM7CADModels";
import IQFusionCADModels from "../../pages/CADFiles/IQFusionCADModels";
import X4CADModels from "../../pages/CADFiles/X4CADModels";
import X5CADModels from "../../pages/CADFiles/X5CADModels";
import X7CADModels from "../../pages/CADFiles/X7CADModels";
import Home from "../../pages/Home";
import PacksizeAprovedMarketingContent from "../../pages/PackksizeOverview/PAMC";
import PacksizeQualificationandBusinessCaseResource from "../../pages/PackksizeOverview/PBCR";
import PacksizeCustomerSporting from "../../pages/PackksizeOverview/PCS";
import PacksizeInstallationandOperationResource from "../../pages/PackksizeOverview/PIOR";
import PacksizePortalOverview from "../../pages/PackksizeOverview/PPO";
import PacksizeProductOverviewandTrainingVideos from "../../pages/PackksizeOverview/PPOTV";
import PacksizeProjectSubmissionForm from "../../pages/PackksizeOverview/PPSF";
import PacksizePlatformSolutioningResourceGuide from "../../pages/PackksizeOverview/PPSRG";
import PacksizeProductSaleandSpecSheets from "../../pages/PackksizeOverview/PPSS";
import PacksizeStandardSolutionEXamples from "../../pages/PackksizeOverview/PSSE";

import UserLayout from "../../Layouts/UserLayout";
import AddPartner from "../../pages/Admin/AddPartner/Index";
import AddUser from "../../pages/Admin/AddUser/Index";
import AGCM from "../../pages/Admin/CADFiles/AG";
import EM7CM from "../../pages/Admin/CADFiles/EM7";
import IQCM from "../../pages/Admin/CADFiles/IQ";
import X4CM from "../../pages/Admin/CADFiles/X4";
import X5 from "../../pages/Admin/CADFiles/X5";
import X7CM from "../../pages/Admin/CADFiles/X7";
import CADS from "../../pages/Admin/CADS";
import ListUser from "../../pages/Admin/ListUser";
import PAMC from "../../pages/Admin/PAMC";
import PBCR from "../../pages/Admin/PBCR";
import PCS from "../../pages/Admin/PCS";
import PIOR from "../../pages/Admin/PIOR";
import PPO from "../../pages/Admin/PPO";
import PPOTV from "../../pages/Admin/PPOTV";
import PPSF from "../../pages/Admin/PPSF";
import PPSRG from "../../pages/Admin/PPSRG";
import PPSS from "../../pages/Admin/PPSS";
import PSSE from "../../pages/Admin/PSSE";
import PartnerList from "../../pages/Admin/PartnerList";
import TestFileUpload from "../../pages/TestFileUpload";

const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Login />} path={routes.login.path} />
        <Route element={<PacksizePortalOverview />} path={routes.ppo.path} />
        <Route element={<Home />} path={routes.home.path} />
        <Route element={<UserLayout />} path="">
          <Route
            element={<PacksizeProductOverviewandTrainingVideos />}
            path={routes.ppotv.path}
          />
          <Route
            element={<PacksizeProductSaleandSpecSheets />}
            path={routes.ppss.path}
          />
          <Route
            element={<PacksizePlatformSolutioningResourceGuide />}
            path={routes.ppsrg.path}
          />
          <Route
            element={<PacksizeStandardSolutionEXamples />}
            path={routes.psse.path}
          />
          <Route
            element={<PacksizeCustomerSporting />}
            path={routes.pcs.path}
          />
          <Route
            element={<PacksizeInstallationandOperationResource />}
            path={routes.pior.path}
          />
          <Route
            element={<PacksizeQualificationandBusinessCaseResource />}
            path={routes.pbcr.path}
          />
          <Route
            element={<PacksizeAprovedMarketingContent />}
            path={routes.pamc.path}
          />
          <Route
            element={<PacksizeProjectSubmissionForm />}
            path={routes.ppsf.path}
          />
                  <Route element={<X5CADModels />} path={routes.x5cm.path} />
        <Route element={<X4CADModels />} path={routes.x4cm.path} />
        <Route element={<X7CADModels />} path={routes.x7cm.path} />
        <Route element={<EM7CADModels />} path={routes.em7cm.path} />
        <Route element={<IQFusionCADModels />} path={routes.iqfcm.path} />
        <Route element={<AutoGluerCADModels />} path={routes.agcm.path} />
        </Route>

        <Route path="/admin" element={<AdminLayout />}>
          <Route path="" element={<Dashboard />} />
          <Route path="users" element={<ListUser/>} />
          <Route path="partners" element={<PartnerList/>} />
          <Route path="adduser" element={<AddUser/>} />
          <Route path="addpartner" element={<AddPartner/>} />
          <Route path="CADs" element={<CADS/>} />

          <Route path="ppo" element={<PPO />} />
          <Route path="ppss" element={<PPSS />} />
          <Route path="ppotv" element={<PPOTV />} />
          <Route path="ppsrg" element={<PPSRG />} />
          <Route path="psse" element={<PSSE />} />
          <Route path="pior" element={<PIOR />} />
          <Route path="pbcr" element={<PBCR />} />
          <Route path="ppsf" element={<PPSF />} />
          <Route path="cads" element={<CADS />} />
          <Route path="cads/x5" element={<X5 />} />
          <Route path="cads/x7" element={<X7CM />} />
          <Route path="cads/x4" element={<X4CM />} />
          <Route path="cads/agcm" element={<AGCM />} />
          <Route path="cads/iqf" element={<IQCM />} />
          <Route path="cads/em7" element={<EM7CM />} />
          {/* <Route path="cads/em7" element={<EM7CM />} /> */}





          <Route path="pcs" element={<PCS />} />
          <Route path="pamc" element={<PAMC />} />

          <Route path="" element={<Dashboard />} />
        </Route>
        <Route element={<TestFileUpload />} path={"/file-upload"} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
