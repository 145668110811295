import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "../../common/services/UserService";
import { setToken } from "../../common/utils/token";
import config from "../../config";
import pLogo from "./pLogo.jpg";
import packsize from "./packsize_img.svg";
import "./styles.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleHelpItemClick = () => {
    // Add the logic for "Forget Password"
  };

  const handleSupportItemClick = () => {
    // Add the logic for "Help"
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // You can add your login logic here

    const payload = {
      username: email,
      password: password,
    };
    Auth.login(payload)
      .then((response: any) => {
        if (response.token) {
          setToken({
            name: config.tokenName,
            value: JSON.stringify(response.token),
          });
          const group = response.group;
          toast.success("Login Successful");
          if (group === "ADMIN") {
            // removeToken({ name: config.tokenName });
            navigate("/admin/ppo");
          } else if (group === "USER") {
            navigate("/");
          } else {
            window.location.reload();
          }
        } else {
        }
      })
      .catch((error: any) => {
        toast.error("Login Failed");
      });
  };

  return (
    <div className="outer-container">
      <div className={`main-container ${anchorEl ? "expanded" : ""}`}>
        <div className="image-container">
          <img src={packsize} alt="Packsize" className="p_image" />
          <img src={pLogo} alt="Packsize" className="p_logo" />
        </div>
        <br />
        <form onSubmit={handleSubmit} className="auth-container">
          <div className="input-group">
            <label>Email</label>
            <input type="email" value={email} onChange={handleEmailChange} />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="checkbox-group">
            <input
              type="checkbox"
              className="rememberMebox"
              name="rememberMe"
              style={{ cursor: "pointer" }}
            />
            <label className="rememberMe">Remember me</label>
          </div>
          <div className="button-container">
            <button
              type="submit"
              className="button"
              style={{ cursor: "pointer" }}
            >
              Login
            </button>
          </div>
          <div className="needHelp">
            <p onClick={handleMenuClick} className="help">
              Need help Signing up?
            </p>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleHelpItemClick} className="subtext">
                Forget Password
              </MenuItem>
              <MenuItem onClick={handleSupportItemClick} className="subtext">
                Help
              </MenuItem>
            </Menu>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
