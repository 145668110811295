import React, { useEffect, useState } from "react";
import NavBar from "../../../common/components/NavBar";
import { Container } from "@mui/material";
import x7cadService from "../../../common/services/x7cad";
import CustomParser from '../../../common/components/CustomEditor/CustomParser'
import { IBlocks } from '../../../common/components/CustomEditor'
import { toast } from "react-toastify";


const X7CADModels = () => {
  const [data, setData] = useState<IBlocks[]>([]);
  useEffect(() => {
    x7cadService
      .getx7cad()
      .then((res: any) => {
        const dataString = res[0]?.htmltext;
        setData(JSON.parse(dataString).data);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  }, []);

  return (
    <>
      <>
        <NavBar />
        <Container>
          {
            data && data.map((block) => {
              return <CustomParser block={block} />
            })
          }
        </Container>
      </>
    </>
  );
}

export default X7CADModels
