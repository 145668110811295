import React, { useEffect, useState } from "react";
import NavBar from "../../../common/components/NavBar";
import Footer from "../../../common/components/Footer";
import PpoTvService from "../../../common/services/PpoTv";
import { Container } from "@mui/system";
import { IBlocks } from "../../../common/components/CustomEditor";
import CustomParser from "../../../common/components/CustomEditor/CustomParser";

const PacksizeProductOverviewandTrainingVideos = () => {
  const [data, setData] = useState<IBlocks[]>([]);

  useEffect(() => {
    PpoTvService.getPPOTV()
      .then((res: any) => {
        // console.log(res[0]?.htmltext);
        const dataString = res[0]?.htmltext;
        setData(JSON.parse(dataString).data);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <NavBar />
      <Container>
        {
          data && data.map((block) => {
            return <CustomParser block={block} />
          })
        }
      </Container>
      <Footer />
    </>
  );
};

export default PacksizeProductOverviewandTrainingVideos;
