import { Container } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IBlocks } from '../../../common/components/CustomEditor';
import CustomParser from '../../../common/components/CustomEditor/CustomParser';
import NavBar from "../../../common/components/NavBar";
import x4cadService from "../../../common/services/x4cad";

const X4CADModels = () => {
  const [data, setData] = useState<IBlocks[]>([]);
  useEffect(() => {
    x4cadService
      .getx4cad()
      .then((res: any) => {
        const dataString = res[0]?.htmltext;
        setData(JSON.parse(dataString).data);
      })
      .catch((error: any) => {
        toast.error('Failed to load!')
      });
  }, []);

  return (
    <>
      <>
        <NavBar />
        <Container>
          {
            data && data.map((block) => {
              return <CustomParser block={block} />
            })
          }
        </Container>
      </>
    </>
  );
}

export default X4CADModels