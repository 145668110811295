import style from './style.module.scss'
import { useNavigate } from 'react-router-dom'
import PPSSIcon from '../../../assets/OverviewIcon/PPSS.png'
import PPOTVIcon from '../../../assets/OverviewIcon/PPOTV.png'
import PPSRGIcom from '../../../assets/OverviewIcon/PPSRG.png'
import PSSEIcon from '../../../assets/OverviewIcon/PSSE.png'
import PCSIcon from '../../../assets/OverviewIcon/PCS.png'
import PIORIcon from '../../../assets/OverviewIcon/PIOR.png'
import PBCRIcon from '../../../assets/OverviewIcon/PBCR.png'
import PAMCIcon from '../../../assets/OverviewIcon/PAMC.png'
import PPSFIcon from '../../../assets/OverviewIcon/PPSF.png'

import X5 from '../../../assets/machineLogo/X5CM.png'
import X7 from '../../../assets/machineLogo/X7CM.png'
import x4 from '../../../assets/machineLogo/X4CM.png'
import em7 from '../../../assets/machineLogo/EM7CM.png'
import iqf from '../../../assets/machineLogo/IQFCM.png'
import AGCM from '../../../assets/machineLogo/AGCM.png'
import Footer from '../Footer'

const LandingPage = () => {
  const navigate = useNavigate();


  const packsizeOverview = [
    {
      image: `${PPSSIcon}`,
      alt: 'PPSS',
      title: 'Packsize Product Sale & Spec Sheets',
      page: '/ppss/'
    }, {
      image: `${PPOTVIcon}`,
      alt: 'PPOTV',
      title: 'Packsize Product Overview & Training Videos',
      page: '/ppotv/'
    }, {
      image: `${PPSRGIcom}`,
      alt: 'PPSRG',
      title: 'Packsize Platform Solutioning Resource Guides',
      page: '/ppsrg/'
    }, {
      image: `${PSSEIcon}`,
      alt: 'PSSE',
      title: 'Packsize Standard Solution Examples',
      page: '/psse/'
    }, {
      image: `${PCSIcon}`,
      alt: 'PCS',
      title: '  Packsize Customer Spotlights',
      page: '/pcs/'

    }, {
      image: `${PIORIcon}`,
      alt: 'PIOR',
      title: 'Packsize Installation and Operation Resources',
      page: '/pior/'
    }, {
      image: `${PBCRIcon}`,
      alt: 'PBCR',
      title: 'Packsize Qualification & Business Case Resources',
      page: '/pbcr/'
    }, {
      image: `${PAMCIcon}`,
      alt: 'PAMC',
      title: 'Packsize Approved Marketing Content',
      page: '/pamc/'
    }, {
      image: `${PPSFIcon}`,
      alt: 'PPSF',
      title: 'Packsize Project Submission Form',
      page: '/ppsf/'
    },

  ]
  const packsizeCardCAD = [
    {
      image: `${X5}`,
      alt: 'X5CM',
      title: 'X5 CAD Models',
      page: '/x5cm/'
    },
    {
      image: `${x4}`,
      alt: 'X4CM',
      title: 'X4 CAD Models',
      page: '/x4cm/'
    },
    {
      image: `${X7}`,
      alt: 'X7CM',
      title: 'X7 CAD Models',
      page: '/x7cm/'
    },
    {
      image: `${em7}`,
      alt: 'EM7',
      title: 'EM7 CAD Models',
      page: '/em7cm/'
    },
    {
      image: `${iqf}`,
      alt: 'IQF',
      title: 'iQ Fusion CAD Models',
      page: '/iqfcm/'
    },
    {
      image: `${AGCM}`,
      alt: 'AGCM',
      title: 'AutoGluer CAD Models',
      page: '/agcm/'
    },
  ];
  const handlePacksizeOverviewDetail = (page: any) => {
    navigate(page);
  };
  const handlePacksizeCADDetails = (page: any) => {
    navigate(page,);
  }

  return (
    <>
      <div className={style.landingPage}>
        <div className={style.introImage}>

          <h1 className={style.introTitle}>
            Packsize Partner Portal
          </h1>
          <div className={style.introNote}>
            Here you will find a wealth of solutioning resources and related information to empower you to assist your customers
          </div>



        </div>
        <section>
          <div className={style.mainBody}>
            <div className={style.mainBodyChild}>
              <div className={style.overview}>
                <h3>Resources</h3>
                <div className={style.overviewChild}>
                  {packsizeOverview.map((overviewItem, index) => (
                    <div className={style.overviewCard} key={index}
                      title={overviewItem.title}
                      onClick={() => handlePacksizeOverviewDetail(overviewItem.page)}>
                      <img src={overviewItem.image} alt={overviewItem.alt} />
                      <span className={style.overviewTitle}>
                        {overviewItem.title}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className={style.CADFiles}>
                <h3>CAD Files</h3>
                <div className={style.CADFilesChild}>
                  {packsizeCardCAD.map((cardCAD, index) => (
                    <div className={style.CADFilesCard} key={index}
                      onClick={() => handlePacksizeCADDetails(cardCAD.page)}
                    >
                      <img src={cardCAD.image} alt={cardCAD.alt} />
                      <span className={style.CADFileTitle}>
                        {cardCAD.title}
                      </span>
                    </div>
                  ))}

                </div>
              </div>
            </div>

          </div>

        </section>
        <section>
          <div>
            <Footer />
          </div>
        </section>


      </div>
    </>

  )
}

export default LandingPage